<template>
  <div class="vcard__list">
    <table class="vcard__list__table">
      <tr>
        <th>Nombre</th>
        <th>Puesto</th>
        <th>Sucursal</th>
      </tr>
      <tr v-for="card in getAllCards" :key="card.id">
        <td>{{ card.name }} {{ card.last }}</td>
        <td>{{ card.job }}</td>
        <td>{{ card.sucursal }}</td>
        <td class="vcard__list__table__actions">
            <button
             @click="doCopy(card.sucursal, card.id, card.name + ' ' + card.last)"
             class="vcard__list__table__action share-card"
            >
                Enlace
            </button>
          
           <button
            @click="$router.push({ 
                name: 'vcard-update',
                params: {
                    id: card.id
                } 
            })"
            class="vcard__list__table__action update-card"
            >
                Editar
            </button>
          <button 
            @click="deleteVCard(card.id, card.visitsId)"
            class="vcard__list__table__action delete-card">
            Eliminar
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "cardsTable",

  methods: {
    async deleteVCard(cardId, visitsId) {
        try {
          await this.deleteCard({
            cardId,
            visitsId
          })
          this.$toast.success('Se elimino la Vcard correctamente')
        } catch (error) {
            this.$toast.error('Ocurrio un error al eliminar la Vcard')
        }
    },

    doCopy(sucursal, id, name) {
      const n = name.toLowerCase().split(" ").join("").normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      let url =
        sucursal == "dm-sur"
          ? `https://card.dimedicalcorporativo.mx/sur/${id}?name=@${n}`
          : `https://card.dimedicalcorporativo.mx/corporativo/${id}?name=@${n}`;
      this.$copyText(url)
        .then(() => {
          this.$toast.open("Link copiado al portapapeles");
        })
        .catch(() => {
          this.$toast.error("Ocurrio un error copiando al portapapeles");
        });
    },

    ...mapActions('vCard', ['deleteCard'])
  },
  computed: {
    ...mapGetters("vCard", ["getAllCards"])
  },
};
</script>

<style>
</style>
