var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vcard__list"},[_c('table',{staticClass:"vcard__list__table"},[_vm._m(0),_vm._l((_vm.getAllCards),function(card){return _c('tr',{key:card.id},[_c('td',[_vm._v(_vm._s(card.name)+" "+_vm._s(card.last))]),_c('td',[_vm._v(_vm._s(card.job))]),_c('td',[_vm._v(_vm._s(card.sucursal))]),_c('td',{staticClass:"vcard__list__table__actions"},[_c('button',{staticClass:"vcard__list__table__action share-card",on:{"click":function($event){return _vm.doCopy(card.sucursal, card.id, card.name + ' ' + card.last)}}},[_vm._v(" Enlace ")]),_c('button',{staticClass:"vcard__list__table__action update-card",on:{"click":function($event){return _vm.$router.push({ 
              name: 'vcard-update',
              params: {
                  id: card.id
              } 
          })}}},[_vm._v(" Editar ")]),_c('button',{staticClass:"vcard__list__table__action delete-card",on:{"click":function($event){return _vm.deleteVCard(card.id, card.visitsId)}}},[_vm._v(" Eliminar ")])])])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Nombre")]),_c('th',[_vm._v("Puesto")]),_c('th',[_vm._v("Sucursal")])])
}]

export { render, staticRenderFns }